import { Injectable } from '@angular/core';
import {
    BehaviorSubject,
    Observable,
    map,
    of,
    take,
} from 'rxjs';
import { AppRestService } from '@app/shared/app-rest';
import { UserData, UserInfo } from '@app/models/user-data.model';
import { AppAuthorizationService } from '../authorization/authorization.service';
import { KeySettings, SettingsCacheService } from '@app/shared/settings-cache';
import { Preferences, PreferencesService } from '@app/shared/preferences/preferences.service';

@Injectable({
    providedIn: 'root',
})
export class UserInfoService {
    userInfo = new UserData();
    private userPermissionsLoaded$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);
    private userDataLoaded$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);
    defaultPref: Preferences;

    constructor(
        private appRest: AppRestService,
        private authService: AppAuthorizationService,
        private cacheService: SettingsCacheService,
        private preferenceService: PreferencesService
    ) { }

    getUserData(): Observable<UserData> {
        this.authService
            .getAuth0UserSubscription()
            .subscribe((userData: any) => {
                if (userData) {
                    this.userInfo.email = userData.email;
                    this.userInfo.emailVerified = userData.email_verified;
                    this.userInfo.familyName = userData?.family_name;
                    this.userInfo.givenName = userData?.given_name;
                    this.userInfo.permissions =
                        userData['https://www.api360.com/permissions'];
                    this.cacheService.saveDataToLocalStorage(
                        KeySettings.UserPermissions,
                        this.userInfo.permissions
                    );
                    this.userPermissionsLoaded$.next(true);
                    this.userInfo.name = userData?.name;
                    this.userInfo.nickName = userData?.nickname;
                    this.userInfo.picture = userData?.picture;
                    this.userInfo.userAuth0Id = userData?.sub;
                    this.userInfo.userLastUpdated = userData?.upadated_at;
                    this.getUserFromServer().subscribe((user) => {
                        this.userInfo.userName = user.userName;
                        this.userInfo.userFullName = user.userFullName;
                        this.userInfo.userCompanyContext =
                            user.userCompanyContext;
                        this.userInfo.userCompanyName = user.userCompanyName;
                        this.cacheService.saveDataToLocalStorage(
                            KeySettings.UserData,
                            this.userInfo
                        );
                        this.preferenceService
                            .getDefaultPreferences(user.userCompanyContext)
                            .pipe(take(1))
                            .subscribe(pref => {
                                pref.companyContext = user.userCompanyContext;
                                this.defaultPref = pref;
                                this.cacheService.saveDataToLocalStorage(KeySettings.DefaultPreferences, this.defaultPref);
                            });
                        this.preferenceService
                            .getUserPreferences(user.userCompanyContext)
                            .pipe(take(1))
                            .subscribe( (res: Preferences) => {
                                if (!res) {
                                    this.cacheService.saveDataToLocalStorage(KeySettings.Preferences, this.defaultPref);
                                }
                                else {
                                    res.companyContext = user.userCompanyContext;
                                    this.cacheService.saveDataToLocalStorage(KeySettings.Preferences, res);
                                }
                            }
                            );
                        this.userDataLoaded$.next(true);
                    });
                }
            });
        return of(this.userInfo);
    }

    getUserFromServer(): Observable<UserInfo> {
        return this.appRest.get(`userInfos`).pipe(
            map((res) => {
                return res as UserInfo;
            })
        );
    }

    isUserPermissionLoaded(): BehaviorSubject<boolean> {
        return this.userPermissionsLoaded$;
    }

    isUserDataLoaded(): BehaviorSubject<boolean> {
        return this.userDataLoaded$;
    }
}
