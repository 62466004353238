import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

export const PERCENTAGE = (partialValue: number, totalValue: number): number => (100 * partialValue) / totalValue;

@Injectable({
  providedIn: 'root'
})
export class ExportService {
  public exportToExcel(data: Array<any>, chunkSize: number, fileName: string, sheetNamePrefix: string, columnsOrder: string[] = []): void {
    const totalRows = data?.length;
    if(!totalRows || totalRows === 0) { return; }
    const totalChunks = Math.ceil(totalRows / chunkSize);
    const workbook = XLSX.utils.book_new();

    for (let i = 0; i < totalChunks; i++) {
      const start = i * chunkSize;
      const end = Math.min(start + chunkSize, totalRows);
      const chunkData = data.slice(start, end);
      const sheetName = `${sheetNamePrefix}${i + 1}`;
      const worksheet = XLSX.utils.json_to_sheet(chunkData, { header: columnsOrder });

      XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    }

    XLSX.writeFile(workbook, fileName);
  }
}
