import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    DevicePositionColumnSettings,
    DevicePositionData,
    DevicePositionDataEnum,
} from './device-position.interface';
import {
    ExportService,
    GridComponent,
    GridService,
    KeySettings,
    PERCENTAGE,
    PageSettingsCache,
    SettingsCacheService,
    TableField,
} from '@app/shared';
import { DevicePositionService } from './device-position.service';
import { HeartbeatsService } from '../heartbeats/heartbeats.service';
import {
    ColDef,
    ColumnState,
    ColumnVO,
    GridReadyEvent,
} from '@ag-grid-community/core';
import { Observable, Subject, filter, take, takeUntil } from 'rxjs';
import { devicePositionSettings } from './device-position.settings';
import { TableFieldTypes } from '@app/shared/operators';
import { CompanyContextService } from '@app/services/company-context/company-context.service';
import { AppDialogService } from '@app/shared/app-dialog/app-dialog.service';
import { SetLocationComponent } from './set-location/set-location.component';
import { DialogSizes } from '@app/shared/app-dialog/app-dialog.config';
import { AppPermissionService } from '@app/shared/app-permission/app-permission.service';
import { AppPermissions } from '@app/shared/app-permission/app-permissions';
import { NavigationStart, Router } from '@angular/router';
import { PreferencesService } from '@app/shared/preferences/preferences.service';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { EnvironmentService } from 'environments/environment.service';

@Component({
    selector: 'app-device-position',
    templateUrl: './device-position.component.html',
    styleUrls: ['../../shared/grid/grid.component.scss'],
})
export class DevicePositionComponent
    extends GridComponent
    implements OnInit, OnDestroy
{
    private isGridDataLoaded = false;
    public rowData!: Array<DevicePositionData>;

    public selectedDeviceId: string | null = null;
    public selectedDevice: DevicePositionData;
    devicePositionSettings = devicePositionSettings;
    locationLabel = 'Set Location';
    hasPermissionManageDevicePosition = false;
    destroy$: Subject<void> = new Subject();


    constructor(
        private heartbeatsService: HeartbeatsService,
        private devicePositionService: DevicePositionService,
        private exportService: ExportService,
        public override settingsCacheService: SettingsCacheService,
        private gridService: GridService,
        public _companyContextService: CompanyContextService,
        public appDialogService: AppDialogService,
        private permissionService: AppPermissionService,
        private router: Router,
        private prefService: PreferencesService,
        private env: EnvironmentService
    ) {
        super();
        LicenseManager.setLicenseKey(this.env.agGridLicenseKey);
    }

    public ngOnInit(): void {
        this.permissionService.user$.subscribe((user) => {
            if (
                user?.permissions.includes(AppPermissions.ManageDevicePosition)
            ) {
                this.hasPermissionManageDevicePosition = true;
            }
        });

        this.prefService.enableSavingGridPreferences(
            KeySettings.DevicePositionGrid
        );
        this.router.events
        .pipe(takeUntil(this.destroy$), filter((event) => event instanceof NavigationStart))
        .subscribe(() => {
            const colState =
                this._grid.columnApi?.getColumnState();
            this.settingsCacheService.saveDataToLocalStorage(
                KeySettings.DevicePositionGrid,
                colState
            );
        });

        this.heartbeatsService
            .getTableFieldsMap()
            .pipe(take(1))
            .subscribe({
                next:(tableFieldsMap: Map<string, TableField>) => {
                    const settings = this.settingsCacheService.devicePosition;
                    for (const columnSettings of settings.columnSettingsList) {
                        const rowGroupIndex =
                            settings.serverSideGetRowsRequest.rowGroupCols.findIndex(
                                (column: ColumnVO) =>
                                    column.id === columnSettings.key
                            );
                        const colSettings =
                            devicePositionSettings[
                                DevicePositionDataEnum[
                                    columnSettings.key as keyof typeof DevicePositionDataEnum
                                ]
                            ];
                        const dataFieldName = colSettings.dataFieldName;

                        this.columnDefs.push({
                            field: columnSettings.key,
                            filter: colSettings.suppressFilterable
                                ? null
                                : this.gridService.createFilter(
                                    tableFieldsMap.get(colSettings.dataFieldName)
                                        ?.dataType ?? colSettings.dataFieldName === DevicePositionDataEnum.lastMessageReceivedDateTime ? TableFieldTypes.DATE_TIME : TableFieldTypes.STRING
                                ),
                                filterParams: {
                                    filterOptions : this.gridService.createFilterOptions(
                                        tableFieldsMap.get(colSettings.dataFieldName)
                                        ?.dataType ?? colSettings.dataFieldName === DevicePositionDataEnum.lastMessageReceivedDateTime ? TableFieldTypes.DATE_TIME : TableFieldTypes.STRING
                                    ),
                                    comparator: colSettings.dataFieldName === DevicePositionDataEnum.lastMessageReceivedDateTime
                                     ? (filter: Date, cellValue: string) => {
                                        if (cellValue == null) return 0;
                                        const cellValueAsDate = new Date(cellValue);
                                        if (cellValueAsDate > filter) {
                                            return 1
                                        } else if (cellValueAsDate < filter) {
                                            return -1
                                        }
                                        return 0;
                                    } : null
                                },
                            sortable: !colSettings.suppressMenu,
                            enableRowGroup: true,
                            rowGroup: rowGroupIndex > -1,
                            rowGroupIndex,
                            sort:
                                settings.serverSideGetRowsRequest.sortModel[0]
                                    ?.colId === columnSettings.key
                                    ? settings.serverSideGetRowsRequest.sortModel[0]
                                        ?.sort
                                    : null,
                            hide:
                                !colSettings.alwaysAvailable &&
                                !columnSettings.visible,
                            suppressMenu: colSettings.suppressMenu,
                            lockVisible: colSettings.lockVisible,
                            suppressDragLeaveHidesColumns:
                                colSettings.suppressDragLeaveHidesColumns,
                            headerName:
                                tableFieldsMap.get(dataFieldName)?.displayName ??
                                colSettings.label,
                            cellRenderer: colSettings.cellRendererComponent,
                        } as ColDef);
                    }
                },
                complete: () => {
                    this.columnDefs = [
                        {
                            field: this.GroupRowsByColumn,
                            headerName: "Group rows by column:",
                            hide: true,
                            lockPosition: 'left',
                            showRowGroup: true,
                            cellRenderer: 'agGroupCellRenderer'
                        } as ColDef,
                        ...this.columnDefs
                    ];
                }
            });
    }

    public ngOnDestroy(): void {
        this.companyContextSubscription.unsubscribe();
        this.destroy$.next();
        this.destroy$.complete();
    }

    public refreshDataByContext(): void {
        this._grid.api.showLoadingOverlay();
        this.selectedDeviceId = null;
        this.isGridDataLoaded = false;
        this.devicePositionService
            .devicePosition(
                this.serverSideGetRowsRequest,
                this.paginationPageSize
            )
            .pipe(take(1))
            .subscribe((data: any) => {
                this.rowData = data.rowData;
                this.isGridDataLoaded = true;
                this.refreshData(false);
                this._grid.api.hideOverlay();
            });
    }

    public onGridReady(gridReadyEvent: GridReadyEvent): void {
        this._grid = gridReadyEvent;
        this.companyContextSubscription = this._companyContextService
            .getCompanyContext()
            .subscribe(() => this.refreshDataByContext());

        this._grid.api.addEventListener('columnVisible', (event: any) => {
            this.settingsCacheService.devicePosition = {
                ...this.settingsCacheService.devicePosition,
                columnSettingsList:
                    this.settingsCacheService.devicePosition.columnSettingsList.map(
                        (
                            column: DevicePositionColumnSettings
                        ): DevicePositionColumnSettings => {
                            if (
                                event.column === null ||
                                column.key === event.column.colId
                            ) {
                                return {
                                    ...column,
                                    visible: event.visible,
                                };
                            }

                            return column;
                        }
                    ),
            } as PageSettingsCache<DevicePositionColumnSettings>;
        });

        const filterModel =
            this.settingsCacheService.devicePosition.serverSideGetRowsRequest
                .filterModel;

        if (filterModel) {
            for (const [key, value] of Object.entries(filterModel)) {
                this._grid.api.getFilterInstance(key)?.setModel(value);
            }

            this.refreshData();
        }

        this._initRowsPerPage();
        this._initColumnRowGroup(true);
        this._initDomLayout();

        const keySetting = KeySettings.DevicePositionGrid;
        const gridPreferencesLocal =
            this.settingsCacheService.getDataFromLocalStorage(
                keySetting
            );
        const gridPreferencesDB = this.settingsCacheService.getDataFromLocalStorage(KeySettings.Preferences)?.['preferences']?.[keySetting];

        const gridPreferencesDefault = this.settingsCacheService.getDataFromLocalStorage(
                KeySettings.DefaultPreferences
                )['preferences'][keySetting];

        if (gridPreferencesLocal) {
            this._grid.columnApi.applyColumnState({state: gridPreferencesLocal,applyOrder: true,});
        }
        else if(gridPreferencesDB) {
                this._grid.columnApi.applyColumnState({state: gridPreferencesDB,applyOrder: true,});
        }
        else if(gridPreferencesDefault){
            this._grid.columnApi.applyColumnState({state: gridPreferencesDefault, applyOrder: true});
        }

        const pageSize = this.settingsCacheService.getGridPageSizes?.find(x => x.gridName === KeySettings.DevicePositionGrid);
        if(pageSize){
            this.paginationPageSize = pageSize.pageSize;
            this.cacheBlockSize = pageSize.pageSize;
            this._grid.api.paginationSetPageSize(pageSize.pageSize);
        }
    }

    public getData(
        endRow: number,
        chunkSize: number
    ): Observable<{
        rowCount: number;
        rowData: Array<any>;
    }> {
        return this.devicePositionService
            .devicePosition(
                {
                    ...this.serverSideGetRowsRequest,
                    rowGroupCols: [],
                    groupKeys: [],
                    endRow: endRow,
                },
                chunkSize
            )
            .pipe(take(1));
    }

    public exportToExcel(): void {
        this.isAvailableToExport = false;

        const chunkSize = 1000000;
        const endRow = chunkSize;
        const rowData: Array<DevicePositionData> = [];

        this.getData(endRow, chunkSize).subscribe(
            (data: { rowCount: number; rowData: Array<DevicePositionData> }) =>
                this.updateProgressBar(
                    endRow + chunkSize,
                    rowData,
                    chunkSize,
                    data
                )
        );
    }

    public updateProgressBar(
        endRow: number,
        rowData: Array<DevicePositionData>,
        chunkSize: number,
        data: { rowCount: number; rowData: Array<any> }
    ): void {
        rowData = [...rowData, ...data.rowData];

        this.progressValueExport = Math.round(
            PERCENTAGE(rowData.length, data.rowCount)
        );

        if (rowData.length < data.rowCount) {
            this.getData(endRow, chunkSize).subscribe(
                (data: {
                    rowCount: number;
                    rowData: Array<DevicePositionData>;
                }) =>
                    this.updateProgressBar(
                        endRow + chunkSize,
                        rowData,
                        chunkSize,
                        data
                    )
            );
        } else {
            if(this.isGridDataLoaded) {
                const gridRowsData: DevicePositionData[] = this._getGridRowsData();
                rowData = gridRowsData
                    .map((item) => {
                        return  rowData.find((row) => {
                            return Object.keys(DevicePositionDataEnum)
                                .filter(key => key !== DevicePositionDataEnum.radioButton)
                                .every(key =>
                                    item[key as keyof DevicePositionData] === row[key as keyof DevicePositionData]);
                        });
                    })
                    .filter(row => !!row) as DevicePositionData[];
            }
            const columnsOrder = this._getColumnsOrderForExport(devicePositionSettings, DevicePositionDataEnum);
            const result = this._prepareDataForExport(rowData, devicePositionSettings, DevicePositionDataEnum);
            if(result) {
                this.exportService.exportToExcel(
                    result,
                    chunkSize,
                    'exported_data.xlsx',
                    'Chunk_',
                    columnsOrder
                );
            }

            this.progressValueExport = 0;
            this.isAvailableToExport = true;
        }
    }

    public setLocation() {
        this.appDialogService
            .openDialog({
                title: 'Set Location',
                showDialogButtons: false,
                component: SetLocationComponent,
                dialogSize: DialogSizes.Small,
                panelClass: ['side-slider'],
                data: {
                    deviceSerialNo: this.selectedDeviceId,
                    comment: this.selectedDevice.comments,
                },
            })
            .subscribe((data) => {
                if (data) {
                    this.devicePositionService
                        .setLocation(data)
                        .pipe(take(1))
                        .subscribe((res) => {
                            this.refreshDataByContext();
                        });
                }
            });
    }

    public selected(data: DevicePositionData) {
        this.selectedDeviceId = data.deviceSerialNo;
        this.selectedDevice = data;
    }

    public getDefaultPreferencesForReset(): ColumnState[] | null {
        const keySetting = KeySettings.DevicePositionGrid;
        const gridPreferencesDefault = this.settingsCacheService.getDataFromLocalStorage(
            KeySettings.DefaultPreferences
            )['preferences'][keySetting];
        if (gridPreferencesDefault) {
            return gridPreferencesDefault as ColumnState[];
        }
        return null
    }

    public override onPageSizeChanged(value: number): void {
        this.paginationPageSize = value;
        this.cacheBlockSize = value;
        const currentPageSizes = this.settingsCacheService.getGridPageSizes;
        if(currentPageSizes){
           //check if the gridName already exists in the array and if so update the pageSize
           const index = currentPageSizes.findIndex(x => x.gridName === KeySettings.DevicePositionGrid);
           if(index !== -1){
               currentPageSizes[index].pageSize = value;
           } else{
               currentPageSizes.push({gridName: KeySettings.DevicePositionGrid, pageSize: value});
           }
           this.settingsCacheService.getGridPageSizes = currentPageSizes;
        } else{
            this.settingsCacheService.getGridPageSizes = [{gridName: KeySettings.DevicePositionGrid, pageSize: value}];
        }   
        this._grid.api.paginationSetPageSize(value);
    }
}
