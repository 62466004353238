import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { take } from 'rxjs';
import {
    PermissionOptions,
    PermissionsName,
    permissionConfig,
} from './permissions-config';
import { UserRoleService } from '@app/services/account/user-role.service';
import { UserPermission, UserRole } from '@app/services/account/user-role.interface';

export enum ConfigTypes {
    PERMISSIONS,
    ROLES,
}

@Component({
    selector: 'app-permissions-config',
    templateUrl: './permissions-config.component.html',
    styleUrls: ['./permissions-config.component.scss'],
})
export class PermissionsConfigComponent implements OnInit {
    @Input() permissionsState: string[] = [];
    roles: any[] = [];
    permissions: any[] = [];
    configType: ConfigTypes;
    permissionConfig = permissionConfig;
    @Output() permissionChange: EventEmitter<any> = new EventEmitter();

    constructor(private userRoleService: UserRoleService) {}

    ngOnInit(): void {
        this.userRoleService
            .getRolesAndPermissions()
            .pipe(take(1))
            .subscribe((res: UserRole[]) => {
                res.forEach((role: UserRole) => {
                    this.roles.push({
                        value: role.roleName,
                        roleId: role.roleId,
                        roleName: role.roleName,
                    });
                    role.permissions.forEach((permission: UserPermission) => {
                        if(permission.permissionName.includes('View') && !role.roleName.includes('Viewer')){
                            return;
                        }
                        this.permissions.push({
                            value: permission.permissionName,
                            permissionId: permission.permissionId,
                            roleId: role.roleId,
                        });
                    });
                });
            });
    }

    isViewPermissionOption(options: PermissionOptions[]) {
        return options.includes(PermissionOptions.VIEW);
    }

    isEditPermissionOption(options: PermissionOptions[]) {
        return options.includes(PermissionOptions.MANAGE);
    }

    toggleRestrictedPermission(
        permissionName: PermissionsName,
        availablePermissions: PermissionOptions[]
    ) {
        if (!this.isRestricted(permissionName, availablePermissions)) {
            this.permissionsState = this.permissionsState.filter(
                (permissionValue: string) =>
                    permissionValue !==
                    `${PermissionOptions.VIEW}${permissionName}`
            );
            this.permissionsState = this.permissionsState.filter(
                (permissionValue: string) =>
                    permissionValue !==
                    `${PermissionOptions.MANAGE}${permissionName}`
            );
        }
        this.permissionChange.emit(this.permissionsState);
    }

    toggleViewPermission(permissionName: PermissionsName) {
        if (this.isView(permissionName)) {
            this.permissionsState = this.permissionsState.filter(
                (permissionValue: string) =>
                    permissionValue !==
                    `${PermissionOptions.VIEW}${permissionName}`
            );
        } else {
            this.permissionsState = this.permissionsState.filter(
                (permissionValue: string) =>
                    permissionValue !==
                    `${PermissionOptions.MANAGE}${permissionName}`
            );
            this.permissionsState.push(
                `${PermissionOptions.VIEW}${permissionName}`
            );
        }
        this.permissionChange.emit(this.permissionsState);
    }

    toggleEditPermission(permissionName: PermissionsName) {
        if (this.isEdit(permissionName)) {
            this.permissionsState = this.permissionsState.filter(
                (permissionValue: string) =>
                    permissionValue !==
                    `${PermissionOptions.MANAGE}${permissionName}`
            );
            this.permissionsState = this.permissionsState.filter(
                (permissionValue: string) =>
                    permissionValue !==
                    `${PermissionOptions.VIEW}${permissionName}`
            );
        } else {
            if(permissionName === PermissionsName.Configuration){
                this.permissionsState.push(
                    `${PermissionOptions.MANAGE}${permissionName}`,
                );
            }
            else{
                this.permissionsState.push(
                    `${PermissionOptions.MANAGE}${permissionName}`,
                    `${PermissionOptions.VIEW}${permissionName}`
                );
            }
        }
        this.permissionChange.emit(this.permissionsState);
    }

    isRestricted(
        permissionName: PermissionsName,
        availablePermissions: PermissionOptions[]
    ) {
        let isRestricted!: boolean;
        if (
            this.isEditPermissionOption(availablePermissions) &&
            this.isViewPermissionOption(availablePermissions)
        ) {
            isRestricted =
                !this.permissionsState.includes(
                    PermissionOptions.VIEW + permissionName
                ) &&
                !this.permissionsState.includes(
                    PermissionOptions.MANAGE + permissionName
                );
        }

        if (
            this.isEditPermissionOption(availablePermissions) &&
            !this.isViewPermissionOption(availablePermissions)
        ) {
            isRestricted = !this.permissionsState.includes(
                PermissionOptions.MANAGE + permissionName
            );
        }

        if (
            !this.isEditPermissionOption(availablePermissions) &&
            this.isViewPermissionOption(availablePermissions)
        ) {
            isRestricted = !this.permissionsState.includes(
                PermissionOptions.VIEW + permissionName
            );
        }

        return isRestricted;
    }

    isView(permissionName: PermissionsName) {
        return (
            !this.isEdit(permissionName) &&
            this.permissionsState.includes(
                PermissionOptions.VIEW + permissionName
            )
        );
    }

    isEdit(permissionName: PermissionsName) {
        return this.permissionsState.includes(
            PermissionOptions.MANAGE + permissionName
        )
    }

    getSelectedPermissionsValue(
        permissionName: PermissionsName,
        availablePermissions: PermissionOptions[]
    ) {
        let resultLabel!: string;
        if (
            this.isView(permissionName) &&
            this.isViewPermissionOption(availablePermissions)
        ) {
            resultLabel = 'View';
        }

        if (
            this.isEdit(permissionName) &&
            this.isEditPermissionOption(availablePermissions)
        ) {
            resultLabel = 'Edit';
        }

        if (this.isRestricted(permissionName, availablePermissions)) {
            resultLabel = 'Restricted';
        }

        return resultLabel;
    }
}
