import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    CellClickedEvent,
    ColDef,
    ColGroupDef,
    ColumnState,
    ColumnVO,
    GetRowIdFunc,
    GetRowIdParams,
    GridReadyEvent,
    IServerSideDatasource,
    IServerSideGetRowsParams,
} from '@ag-grid-community/core';
import { HeartbeatsV2Service } from './heartbeatsV2.service';
import {
    EventsV2ColumnSettings,
    EventsV2Data,
    EventsV2DataEnum,
} from './heartbeatsV2.interface';
import { eventsV2Settings } from './heartbeatsV2.settings';
import {
    ExportService,
    GridComponent,
    GridService,
    KeySettings,
    PERCENTAGE,
    PageSettingsCache,
    SettingsCacheService,
    TableField,
} from '@app/shared';
import { Observable, switchMap, filter, take, Subject, takeUntil } from 'rxjs';
import { TableFieldTypes } from '@app/shared/operators';
import { CompanyContextService } from '@app/services/company-context/company-context.service';
import { Router, NavigationStart, ActivatedRoute } from '@angular/router';
import { PreferencesService } from '@app/shared/preferences/preferences.service';
import {
    AppNotificationService,
    NotificationType,
} from '@app/services/notification/notification.service';
import { AppPermissionService } from '@app/shared/app-permission/app-permission.service';
import { Modality } from '@app/models/modality.enum';
import { AppPermissions } from '@app/shared/app-permission/app-permissions';
import { AppDialogService } from '@app/shared/app-dialog/app-dialog.service';
import { DialogSizes } from '@app/shared/app-dialog/app-dialog.config';
import { VehicleDetailsComponent } from '../vehicles/vehicle-details/vehicle-details.component';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { EnvironmentService } from 'environments/environment.service';


@Component({
    selector: 'app-heartbeats-v2',
    templateUrl: './heartbeatsV2.component.html',
    styleUrls: ['../../shared/grid/grid.component.scss'],
})
export class HeartbeatsV2Component
    extends GridComponent
    implements OnInit, OnDestroy
{
    public rowData!: Array<EventsV2Data>;
    hasPermissionViewHeartbeats = false;
    hasPermissionManageDefaultPreference = false;
    public getRowId: GetRowIdFunc = (params: GetRowIdParams) => {
        return `${params.data.vehicle}`;
    };
    eventsV2Settings = eventsV2Settings;
    destroy$: Subject<void> = new Subject();
    hasPermissionShowWatermarkTimeStamp = false;


    constructor(
        private heartbeatsV2Service: HeartbeatsV2Service,
        private exportService: ExportService,
        public override settingsCacheService: SettingsCacheService,
        private router: Router,
        private prefService: PreferencesService,
        public _companyContextService: CompanyContextService,
        private permissionService: AppPermissionService,
        private notificationService: AppNotificationService,
        private route: ActivatedRoute,
        private gridService: GridService,
        private appDialogService: AppDialogService,
        private env: EnvironmentService
    ) {
        super();
        LicenseManager.setLicenseKey(this.env.agGridLicenseKey);
    }

    public ngOnInit(): void {
        this.permissionService.user$.subscribe((user) => {
            this.setModalityAndPermissions(user?.permissions);
            if (this.hasPermissionViewHeartbeats) {
                this.prefService.enableSavingGridPreferences(
                    KeySettings.HeartbeatsV2Grid
                );
                this.router.events
                    .pipe(takeUntil(this.destroy$),filter((event) => event instanceof NavigationStart))
                    .subscribe(() => {
                        const colState = this._grid.columnApi?.getColumnState();
                        this.settingsCacheService.saveDataToLocalStorage(
                            KeySettings.HeartbeatsV2Grid,
                            colState
                        );
                    });
                this.continueProcessing();
            } else {
                this.notificationService.showSnackBar(
                    'You lack permission to view Heartbeats!',
                    NotificationType.Error,
                    3000
                );
            }
        });
    }

    public ngOnDestroy(): void {
        this.companyContextSubscription?.unsubscribe();
        this.destroy$.next();
        this.destroy$.complete();
    }

    private continueProcessing(): void {
        this.heartbeatsV2Service
        .getTableFieldsMap()
        .pipe(take(1))
        .subscribe({
            next: (tableFieldsMap: Map<string, TableField>) => {
                const heartbeats = this.settingsCacheService.heartbeatsV2;

                for (const columnSettings of heartbeats.columnSettingsList) {
                    const rowGroupIndex =
                        heartbeats.serverSideGetRowsRequest.rowGroupCols.findIndex(
                            (column: ColumnVO) =>
                                column.id === columnSettings.key
                        );
                    const colSettings =
                        eventsV2Settings[
                            EventsV2DataEnum[
                                columnSettings.key as keyof typeof EventsV2DataEnum
                            ]
                        ];
                    const dataFieldName = colSettings.dataFieldName;
                    const tableField = tableFieldsMap.get(dataFieldName);

                    const newColumn = {
                        field: columnSettings.key,
                        filter: this._createFilter(
                            tableFieldsMap,
                            columnSettings.key
                        ),
                        filterParams: {filterOptions : this.gridService.createFilterOptions(
                            tableFieldsMap.get(colSettings.dataFieldName)
                            ?.dataType ?? TableFieldTypes.STRING
                        )},
                        sortable: true,
                        enableRowGroup: true,
                        rowGroup: rowGroupIndex > -1,
                        rowGroupIndex: rowGroupIndex > -1 ? rowGroupIndex : null,
                        sort:
                            heartbeats.serverSideGetRowsRequest.sortModel[0]
                                ?.colId === columnSettings.key
                                ? heartbeats.serverSideGetRowsRequest
                                    .sortModel[0]?.sort
                                : null,
                        hide: !columnSettings.visible,
                        headerName:
                            tableFieldsMap.get(dataFieldName)?.displayName ??
                            colSettings.label,
                        cellRenderer: colSettings.cellRendererComponent,
                    } as ColDef;

                    if(colSettings.dataFieldName === 'vehicleInfo.vehicleID') {
                        newColumn.onCellClicked = (event: CellClickedEvent) => {
                            this.showVehicleDetails(event.data);
                        };
                    }

                    if (tableField?.dataFieldGroupName) {
                        const columnGroupIndex = this.columnDefs.findIndex(
                            (colDef: ColDef | ColGroupDef) =>
                                colDef.headerName ===
                                tableField?.dataFieldGroupName
                        );

                        if (columnGroupIndex === -1) {
                            this.columnDefs.push({
                                headerName: tableField?.dataFieldGroupName,
                                marryChildren: true,
                                children: [newColumn],
                            });
                        } else {
                            this.columnDefs[columnGroupIndex] = {
                                ...this.columnDefs[columnGroupIndex],
                                children: [
                                    ...(
                                        this.columnDefs[
                                            columnGroupIndex
                                        ] as ColGroupDef
                                    ).children,
                                    newColumn,
                                ],
                            };
                        }
                    } else {
                        this.columnDefs.push(newColumn);
                    }
                }
            },
            complete: () => {
                this.columnDefs = [
                    {
                        field: this.GroupRowsByColumn,
                        headerName: "Group rows by column:",
                        hide: true,
                        lockPosition: 'left',
                        showRowGroup: true,
                        cellRenderer: 'agGroupCellRenderer'
                    } as ColDef,
                    ...this.columnDefs
                ];
            }
        });
    }
    showVehicleDetails(data: any) {
        const vehicleId = `${data.PTO}:${data.vehicle}`;
        this.appDialogService
        .openDialog({
            title: 'Vehicle Details',
            showDialogButtons: false,
            component: VehicleDetailsComponent,
            dialogSize: DialogSizes.Small,
            panelClass: ['side-slider'],
            data: {
                vehicleToView: {fullVehicleId: vehicleId}
            },
        })
        .subscribe();
    }

    setModalityAndPermissions(userPermissions: string[]): void {
        if (userPermissions.includes(AppPermissions.FeatureHeartbeatsPTOViewsAll)) {
            this.modality = Modality.All;
        } else {
            this.modality = Modality.None;
        }
        if(userPermissions.includes(AppPermissions.ViewHeartbeats)){
            this.hasPermissionViewHeartbeats = true;
        }
        else{
            this.hasPermissionViewHeartbeats = false;
        }
        if(userPermissions.includes(AppPermissions.ManageConfiguration)){
            this.hasPermissionManageDefaultPreference = true;
        }
        else{
            this.hasPermissionManageDefaultPreference = false;
        }
        if(userPermissions.includes(AppPermissions.FeatureShowWatermarkTimeStamp)){
            this.hasPermissionShowWatermarkTimeStamp = true;
        }
        else{
            this.hasPermissionShowWatermarkTimeStamp = false;
        }
    }

    public _createFilter(
        tableFieldsMap: Map<string, TableField>,
        field: string
    ): 'agTextColumnFilter' | 'agNumberColumnFilter' | 'agDateColumnFilter' {
        switch (
            tableFieldsMap.get(
                eventsV2Settings[
                    EventsV2DataEnum[field as keyof typeof EventsV2DataEnum]
                ].dataFieldName
            )?.dataType ??
            TableFieldTypes.STRING
        ) {
            case TableFieldTypes.NUMBER:
                return 'agNumberColumnFilter';
            case TableFieldTypes.DATE_TIME:
                return 'agDateColumnFilter';
        }

        return 'agTextColumnFilter';
    }


    public getRows(serverSideGetRowsParams: IServerSideGetRowsParams<any, any>): void {
        this.serverSideGetRowsRequest = serverSideGetRowsParams.request;

        this.settingsCacheService.heartbeatsV2 = {
            ...this.settingsCacheService.heartbeatsV2,
            serverSideGetRowsRequest: this.serverSideGetRowsRequest,
        } as PageSettingsCache<EventsV2ColumnSettings>;
        this.heartbeatsV2Service
            .companies(
                this.serverSideGetRowsRequest,
                this.paginationPageSize,
                this.modality
            )
            .pipe(take(1))
            .subscribe({
                next: serverSideGetRowsParams.success,
                error:any =>{
                    serverSideGetRowsParams.fail();
                    this.notificationService.showSnackBar(
                        'An error occurred while fetching data!',
                        NotificationType.Error,
                        3000
                    );
                },
                
            });
    }

    public columnsChanged(event: any): void {
        this.settingsCacheService.heartbeatsV2 = {
            ...this.settingsCacheService.heartbeatsV2,
            columnSettingsList:
                this.settingsCacheService.heartbeatsV2.columnSettingsList.map(
                    (
                        column: EventsV2ColumnSettings
                    ): EventsV2ColumnSettings => {
                        if (
                            event.column === null ||
                            column.key === event.column.colId
                        ) {
                            return {
                                ...column,
                                visible: event.visible,
                            };
                        }

                        return column;
                    }
                ),
        } as PageSettingsCache<EventsV2ColumnSettings>;
    }

    public onGridReady(gridReadyEvent: GridReadyEvent): void {
        this._grid = gridReadyEvent;
        this.companyContextSubscription = this._companyContextService.getCompanyContext().subscribe(() => {
            this._grid.api.setServerSideDatasource({
                getRows: this.getRows.bind(this),
            } as IServerSideDatasource);
            this.refreshData(false);
        });

        this._grid.api.addEventListener('displayColumnsChanged', this.columnsChanged.bind(this));

        const filterModel =
            this.settingsCacheService.heartbeatsV2.serverSideGetRowsRequest
                .filterModel;

        if (filterModel) {
            for (const [key, value] of Object.entries(filterModel)) {
                this._grid.api.getFilterInstance(key)?.setModel(value);
            }

            this.refreshData();
        }

        const vehicleId = this.route.snapshot.queryParamMap.get('vehicleId');

        if (vehicleId) {
            this._grid.columnApi?.getColumnState().forEach(item => this._grid.api.destroyFilter(item.colId));

            this._grid.api.getFilterInstance('vehicle')?.setModel({
                type: 'eq',
                filter: vehicleId,
            });

            this.router.navigate(['/heartbeatsV2'], {
                queryParams: { vehicleId: null },
            });

            this.refreshData();
        }

        this._initRowsPerPage();
        this._initColumnRowGroup();
        this._initDomLayout();

        const keySetting = KeySettings.HeartbeatsV2Grid;
        const gridPreferencesLocal =
            this.settingsCacheService.getDataFromLocalStorage(
                keySetting
            );
        const gridPreferencesDB = this.settingsCacheService.getDataFromLocalStorage(
                KeySettings.Preferences
                )['preferences'][keySetting];

        const gridPreferencesDefault = this.settingsCacheService.getDataFromLocalStorage(
                KeySettings.DefaultPreferences
                )['preferences'][keySetting];

        if (gridPreferencesLocal) {
            this._grid.columnApi.applyColumnState({state: gridPreferencesLocal,applyOrder: true,});
        }
        else if (gridPreferencesDB) {
            this._grid.columnApi.applyColumnState({state: gridPreferencesDB,applyOrder: true,});
        }
        else if(gridPreferencesDefault){
            this._grid.columnApi.applyColumnState({state: gridPreferencesDefault, applyOrder: true});
        }
        
        const pageSize = this.settingsCacheService.getGridPageSizes?.find(x => x.gridName === KeySettings.HeartbeatsV2Grid);
        if(pageSize){
            this.paginationPageSize = pageSize.pageSize;
            this.cacheBlockSize = pageSize.pageSize;
            this._grid.api.paginationSetPageSize(pageSize.pageSize);
        }
    }

    public getData(
        endRow: number,
        chunkSize: number
    ): Observable<{
        rowCount: number;
        rowData: Array<any>;
    }> {
        return this._companyContextService.getCompanyContext().pipe(
            switchMap((currentCompany: string) =>
                this.heartbeatsV2Service
                    .query(
                        {
                            ...this.serverSideGetRowsRequest,
                            rowGroupCols: [],
                            groupKeys: [],
                            endRow: endRow,
                        },
                        chunkSize,
                        currentCompany,
                        this.modality
                    )
                    .pipe(take(1))
            )
        );
    }

    public exportToExcel(): void {
        this.isAvailableToExport = false;    
        const chunkSize = 1000000;
        const rowData: Array<EventsV2Data> = [];
        let currentRow = chunkSize;
        let totalRows = 0;
    
        // Start chunked data retrieval
        const fetchChunk = () => {
            this.getData(currentRow, chunkSize).pipe(take(1)).subscribe({
                next: (data: { rowCount: number; rowData: Array<EventsV2Data> }) => {
                    // Update total row count on first chunk
                    if (currentRow === 0) totalRows = data.rowCount;
    
                    // Append new data to rowData
                    rowData.push(...data.rowData);
    
                    // Update progress bar
                    this.progressValueExport = Math.round(
                        PERCENTAGE(rowData.length, totalRows)
                    );
    
                    // Process next chunk or complete export
                    if (rowData.length < totalRows) {
                        currentRow += chunkSize; // Move to next chunk
                        fetchChunk();
                    } else {
                        this.finalizeExport(rowData, chunkSize, totalRows);
                    }
                },
                error: (error) => {
                    console.error("Error fetching data:", error);
                    this.handleExportError(); // Gracefully handle errors
                }
            });
        };
    
        fetchChunk();
    }
    
    private finalizeExport(
        rowData: Array<EventsV2Data>,
        chunkSize: number,
        totalRows: number
    ): void {
        const columnsOrder = this._getColumnsOrderForExport(
            eventsV2Settings,
            EventsV2DataEnum
        );
        const result = this._prepareDataForExport(
            rowData,
            eventsV2Settings,
            EventsV2DataEnum
        );
    
        this.exportService.exportToExcel(
            result,
            chunkSize,
            "exported_data.xlsx",
            "Chunk_",
            columnsOrder
        );
    
        // Reset progress bar and export availability
        this.progressValueExport = 0;
        this.isAvailableToExport = true;
    
        console.log(`Export completed. Total rows exported: ${totalRows}`);
    }
    
    private handleExportError(): void {
        this.isAvailableToExport = true;
        this.progressValueExport = 0;
        console.log("An error occurred during export. Please try again.");
        this.notificationService.showSnackBar(
            'An error occurred while exporting data!',
            NotificationType.Error,
            3000);
    }
    

    public saveDefaultPreferences(){
        this.prefService.updateAllPreferences("defaultPreference")
    }

    public getDefaultPreferencesForReset(): ColumnState[] | null {
        const keySetting = KeySettings.HeartbeatsV2Grid;
        const gridPreferencesDefault = this.settingsCacheService.getDataFromLocalStorage(
            KeySettings.DefaultPreferences
            )['preferences'][keySetting];
        if (gridPreferencesDefault) {
            return gridPreferencesDefault as ColumnState[];
        }
        return null
    }

    public override onPageSizeChanged(value: number): void {
        this.paginationPageSize = value;
        this.cacheBlockSize = value;
        const currentPageSizes = this.settingsCacheService.getGridPageSizes;
        if(currentPageSizes){
            //check if the gridName already exists in the array and if so update the pageSize
            const index = currentPageSizes.findIndex(x => x.gridName === KeySettings.HeartbeatsV2Grid);
            if(index !== -1){
                currentPageSizes[index].pageSize = value;
            } else{
                currentPageSizes.push({gridName: KeySettings.HeartbeatsV2Grid, pageSize: value});
            }
            this.settingsCacheService.getGridPageSizes = currentPageSizes;
        } else{
            this.settingsCacheService.getGridPageSizes = [{gridName: KeySettings.HeartbeatsV2Grid, pageSize: value}];
        }   
        this._grid.api.paginationSetPageSize(value);
    }

}
